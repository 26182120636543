<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3><span v-html="app_name"/> Overview</h3>

	<p>
		<span v-html="app_name"/>  was created to tackle the problem of providing open, equitable, and easy access to learning frameworks to the stakeholders that need them. In the past, standards have often been shared and reviewed as downloadable static PDF files. While easy to produce and distribute, the data encapsulated in a PDF was not ingested for use by digital systems (e.g. student information systems, learning management systems, resource repositories, assessment platforms etc.). Furthermore, PDFs were convenient for printing, but did not allow for the types of rich interaction with data—e.g. searching, browsing, comparing, and data visualization—that modern digital applications can provide.
	</p>

	<p><span v-html="app_name"/> is a tool that allows:</p>
		<ul>
			<li>Easy browsing, searching, and application of standards to lesson planning, resource curation, assessment design, and enhanced learning experiences.</li>
			<li>Quick conversion of existing learning standard frameworks from PDFs/spreadsheets, associations to other frameworks, and authoring tools to organize standards into a machine-readable format (CASE).</li>
			<li>Issuing agencies to take control of their own frameworks, add additional supplemental information, and manage revisions in all stages of the adoption.</li>
			<li>EdTech companies and other organizations that serve education to import the learning standards from one API to any student information systems (SIS), learning management systems (LMS), learning object repositories (LOR), and other platforms.</li>
		</ul>

	<p>Information for standards issuing agencies about publishing learning standards using Satchel{{extra_phrase}} is available in the <span v-html="link('satchel_plans', 'Satchel Publishing Options')"></span> guide.</p>
	<!-- <img srcset="/docimages/overview-1.png 4x" class="k-help-img block"> -->
</div></template>

<script>
import HelpMixin from './HelpMixin'
export default {
	mixins: [HelpMixin],
	computed: {
		extra_phrase() {
			if (this.$store.state.site_config.app_name == 'Standards Satchel') return ''
			return `, the web application that powers ${this.$store.state.site_config.app_name},`
		},
	}
}
</script>

<style lang="scss">
</style>